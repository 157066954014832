@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: url('./static/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: url('./static/fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('./static/fonts/Lato-Black.ttf') format('truetype');
}
